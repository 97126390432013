import PropTypes from "prop-types";
import React, {useState, useEffect} from "react";

import { Row, Col, CardBody, Card, Alert, Container, Form, Input, FormFeedback, Label } from "reactstrap";

//redux
import { useSelector, useDispatch } from "react-redux";
import { createSelector } from "reselect";
import { Link } from "react-router-dom";
import withRouter from "components/Common/withRouter";

// Formik validation
import * as Yup from "yup";
import { useFormik } from "formik";

// actions
import { loginUser, socialLogin } from "../../store/actions";

// import images
import profile from "assets/images/profile-img.png";
import logo from "assets/images/logo.svg";
import scanmeme from './img/scanmeme.svg'
import './login.css'
import logo_bg from './img/login_bg.jpg'
import logo_2 from './img/logo.png'
import logo_color from './img/logo-color.png'
import toast, { Toaster } from 'react-hot-toast';
import {versions} from '../../components/HorizontalLayout/version'
import { IoIosArrowRoundForward } from "react-icons/io";
import login_photo from './img/photo.png'
import icon1 from './img/icon1.png'
import icon2 from './img/icon2.png'
import icon3 from './img/icon3.png'
import icon4 from './img/icon4.png'

import premium_icon1 from './img/premium_icon1.png'
import premium_icon2 from './img/premium_icon2.png'
import { IoClose } from "react-icons/io5";

import Footer from "components/HorizontalLayout/";
import {
  Modal,
} from "reactstrap";
import { Tooltip } from 'react-tooltip'

import star1 from './img/star1.svg'
import star2 from './img/star2.svg'
import star3 from './img/star3.svg'

import tron_logo from './img/tron-logo.png'
import tron_logo2 from './img/tronlink.svg'
import star from './../Bondinglive/img/premium_icon.png'

const loadTronWeb = () => {
  const script = document.createElement('script');
  script.src = 'https://cdn.jsdelivr.net/npm/tronweb/dist/TronWeb.js';
  script.async = true;
  document.body.appendChild(script);
};

const Login = props => {
  const [errorMessage, setErrorMessage] = useState('');
  const [walletAddress, setWalletAddress] = useState('');
  const [authToken, setAuthToken] = useState(null);
  const [isConnected, setIsConnected] = useState(false);
  const [modal_standard, setmodal_standard] = useState(false);
  const [modal_data, setModal_data] = useState(null)
  const [modal_title, setModal_title] = useState(null)

  const tog_standard = () => {
    setmodal_standard(!modal_standard);
    if(!modal_standard){
      setModal_data(null)
      setModal_title(null)
    }
  };

  const redirect_sunio = () => {
    window.open(
      "https://sun.io/#/sun_swap/v2?t0=T9yD14Nj9j7xAB4dbGeiX9h8unkKHxuWwb&t1=TRv9ipj4kKAZqQggQ7ceJpe5ERD1ZShpgs&type=swap", 
      "_blank", 
      "noopener noreferrer"
    );
  };

  const policy = () => {
    return (
      <div className="policy">

        <div className="section">
          <h2 style={{marginTop: 0}}>Disclaimer:</h2>
          <p>Scan.meme is an <strong>informational platform</strong> designed to provide users with data and analytics related to the Sunpump.meme ecosystem. The information provided on Scan.meme is intended solely for informational purposes and does not constitute financial advice, investment recommendations, or any form of buy or sell call to action.</p>
        </div>

        <div className="section">
          <h2>Responsibility:</h2>
          <ul>
            <li><strong>Do Your Own Research (DYOR):</strong> Users are strongly advised to conduct their own thorough research and due diligence before making any financial decisions. Scan.meme provides tools and data to aid in your research, but it is the user's responsibility to verify the accuracy and relevance of this information to their specific circumstances.</li>
            <li><strong>100% User Responsibility:</strong> By using Scan.meme, you acknowledge and agree that you are 100% responsible for any actions or decisions you make based on the information provided by the platform. Scan.meme, its creators, and affiliates will not be held liable for any losses, damages, or consequences arising from your use of the platform.</li>
            <li><strong>No Guarantee of Accuracy:</strong> While Scan.meme strives to provide accurate and up-to-date information, we do not guarantee the completeness, reliability, or accuracy of the data. Market conditions can change rapidly, and past performance is not indicative of future results.</li>
          </ul>
        </div>

        <div className="section">
          <h2>Acceptance of Terms:</h2>
          <p>By accessing and using Scan.meme, you agree to the terms and conditions outlined in this policy. If you do not agree with these terms, you should not use the platform.</p>
        </div>

        <div className="section">
          <p className="cytat">"I Accept the Policy and Understand My Responsibilities"</p>
        </div>

        <div className="section">
          <p>This policy is designed to protect both the platform and its users by clearly stating that Scan.meme is purely an informational tool and that users must take full responsibility for their financial decisions.</p>
        </div>
      </div>
    )
  }

  const access = () => {
    return (
      <div className="guide">
          <div className="section tier">
            <div className="icon"><img src={star1} width={50}/></div>
            <div>
              <b>Core:</b>
              <p>
                Hold 1,000,000 $CCC tokens to access the core features of the platform.
              </p>              
            </div>
          </div>
          <div className="section tier">
            <div className="icon"><img src={star2} width={50}/></div>
            <div>
              <b>Advanced:</b>
              <p>
                Hold 2,000,000 $CCC tokens for all Core features, plus the AI Wallet Scanner + AI Market Maker Detection Scanner
              </p>
            </div>
          </div>
          <div className="section tier">
            <div className="icon"><img src={star3} width={50}/></div>
            <div>
              <b>Master:</b>
              <p>
                Hold 3,000,000 $CCC tokens or be $CCC DAO member for Advanced features, plus InsightX BubbleMaps.
              </p>              
            </div>
          </div>
          
          <div className="section note">
            <p>
              <b>Note:</b>
              <p>To gain access, users must hold the required amount of $CCC tokens in their wallet and connect to the platform using TronLink Wallet.</p>
              <a href="https://t.me/coconutchicken_trx" target="_blank" rel="noopener noreferrer">
                https://t.me/coconutchicken_trx
              </a>
              <br/><br/>
              <p>Purchase $CCC tokens directly here:</p>
              <a href="https://sun.io/#/sun_swap/v2?t0=T9yD14Nj9j7xAB4dbGeiX9h8unkKHxuWwb&t1=TRv9ipj4kKAZqQggQ7ceJpe5ERD1ZShpgs&type=swap" target="_blank" rel="noopener noreferrer">
                https://sun.io/
              </a>
            </p>
          </div>
      </div>
    )
  }

  const dispatch = useDispatch();

  const validation = useFormik({
    // enableReinitialize : use this  flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      email: "admin@themesbrand.com" || '',
      password: "123456" || '',
    },
    validationSchema: Yup.object({
      email: Yup.string().required("Please Enter Your Email"),
      password: Yup.string().required("Please Enter Your Password"),
    }),
    onSubmit: (values) => {
      dispatch(loginUser(values, props.router.navigate));
    }
  });


  const LoginProperties = createSelector(
    (state) => state.Login,
    (login) => ({
      error: login.error
    })
  );

  const {
    error
  } = useSelector(LoginProperties);


  useEffect(() => {
    loadTronWeb();
  }, []);

  useEffect(() => {
    if(errorMessage === "User rejected the signature request."){
      setWalletAddress('')
      setAuthToken(null)
      setIsConnected(false)
    }
  }, [errorMessage]);

  const generateSignedMessage = async (walletAddress) => {
    try {
      const ts = new Date().getTime();
      const app = 'degencave.com';
      const message = JSON.stringify({ walletAddress, ts, app });

      const signature = await window.tronWeb.trx.signMessageV2(message);
      const token = 'Auth2 ' + signature + '.' + window.tronWeb.toHex(message);
      setAuthToken(token);

      return signature;
    } catch (error) {
      setErrorMessage('User rejected the signature request.');
    }
  };

  const handleWalletConnected = async (address) => {
    setWalletAddress(address);
    setIsConnected(true);
    await generateSignedMessage(address);
  };

  const connectWallet = async () => {
    if (window.tronWeb && window.tronWeb.defaultAddress.base58) {
      handleWalletConnected(window.tronWeb.defaultAddress.base58);
    } else if (window && window.tronLink) {
      window.tronLink.request({ method: 'tron_requestAccounts' });
      toast.error('Please open your TronLink and choose the appropriate wallet to connect.');
    }
  };

  const fireRequest = async () => {
    // const url = 'https://cors-anywhere.herokuapp.com/https://api.scan.meme/api/users/validate_auth/';
    const url = 'https://api.scan.meme/api/users/validate_auth/'
    let headers = {
      'Content-Type': 'application/json',
    };
  
    if (authToken) {
      headers['Authorization'] = authToken;
    }
  
    try {
      const response = await fetch(url, {
        method: 'POST',
        headers: headers,
      });
  
      if (!response.ok) {
        console.log('Status:', response.status);
        console.log('Status Text:', response.statusText);
        
        if (response.status === 401) {
          toast.error('Authorization incorrect!');
          setWalletAddress('');
          setAuthToken(null);
          setIsConnected(false);
          return;
        }
  
        if (response.status === 403) {
          const errorData = await response.json().catch(() => null);
          if (errorData) {
            toast.custom(
              (t) => (
                <div
                  className={`${
                    t.visible ? 'animate-enter' : 'animate-leave'
                  } bg-white shadow-lg rounded-lg px-4 py-3`}
                >
                  <div style={{ fontSize: '22px', paddingBottom: '10px', paddingTop: '20px', fontWeight: 'bold' }}>
                    Your login attempt failed.
                  </div>
                  <div>
                    The minimum required amount of tokens has not been deposited to proceed with authorization.
                    <br />
                    You currently hold <b>{errorData.ccc_balance} $CCC</b> tokens, but a minimum of <b>{errorData.minimum_ccc} $CCC</b> tokens is required to access Core features.
                  </div>
                  <br/>
                  <div>
                    <b style={{fontSize: '16px', color: '#000'}}>Please ensure you meet the following tier requirements to continue:</b>
                  </div>
                  <br/>
                  <div>
                    <b>Core: Hold 1,000,000 $CCC tokens</b><br/> to access the core features of the platform.<br/>
                    <b>Advanced: Hold 2,000,000 $CCC tokens</b><br/> for all Core features, plus the AI Wallet Scanner.<br/>
                    <b>Master: Hold 3,000,000 $CCC tokens or be a $CCC DAO member</b><br/>  for Advanced features, plus InsightX BubbleMaps.<br/>
                  </div>
        
                  <br/><br/>
                  <p>
                    Purchase $CCC tokens directly here:
                    <b>
                      <a href="https://sun.io/#/sun_swap/v2?t0=T9yD14Nj9j7xAB4dbGeiX9h8unkKHxuWwb&t1=TRv9ipj4kKAZqQggQ7ceJpe5ERD1ZShpgs&type=swap" target="_blank" rel="noopener noreferrer" style={{color: '#0081DB'}}>
                        https://sun.io/
                      </a>
                    </b>
                  </p>
        
                  <button
                    onClick={() => toast.dismiss(t.id)}
                    className="ml-4 bg-red-500 text-white rounded-full px-2 py-1 closebtn"
                  >
                    <IoClose color="#000" size={15} />
                  </button>
        
                  <button
                    onClick={() => toast.dismiss(t.id)}
                    className="closebtn2"
                  >
                    Close
                  </button>
                </div>
              ),
              { duration: 97000 }
            );
          }
          setWalletAddress('');
          setAuthToken(null);
          setIsConnected(false);
          return;
        }
  
        return; 
      }
  
      const data = await response.json();
      toast.success('Authorization correct!');
      sessionStorage.setItem('authToken', authToken);
      sessionStorage.setItem('accessTier', data.tier);
      setIsConnected(false);
  
      setTimeout(() => {
        validation.handleSubmit();
      }, 2000);
    } catch (error) {
      console.error('Error:', error);
    }
  };

  useEffect(() => {
    if(authToken && isConnected){
      fireRequest()
    }
  }, [authToken, isConnected]);

  return (
    <React.Fragment>
      <div className="login">
        <Toaster />
        <div className="login-column">

          <div className="top-bar">
            <div className="logotype">
              <img src={scanmeme} alt="" width={400}/>
            </div>
            <div className="login-buttons">
              <button
                className="btn btn-primary btn-block btn-login-black"
                onClick={()=>{
                  tog_standard()
                  setModal_data(access())
                  setModal_title('Access Tiers')
                }}
              >
                Access Tiers
              </button>
              <button 
                className="btn btn-primary btn-block btn-login"
                onClick={connectWallet} 
                disabled={isConnected}
                data-tooltip-id="tooltip_login"
                data-tooltip-html={'To log in, you need to use the TronLink desktop<br/>or mobile wallet with $CCC Coconut Chicken that is TRC20 token.<br/>Check Access Tiers information for details.'}
              >
                {isConnected ? 
                (
                  <>
                    Connecting Wallet ...
                  </>
                )
                : (
                  <>
                    Connect TronLink <img src={tron_logo2} alt="tron logo" style={{ width: '26px' }} />
                  </>
                )}
              </button>
            </div>
          </div>

          <div className="login-contnent">
            <CardBody className="pt-0">
              <div className="p-2">
                <div className="form-horizontal">
                  <div className="content-title">
                    <h1>Stay Ahead, Spot Gems, and Avoid Rugs!</h1>
                    <p>
                      Welcome to scan.meme, the most advanced analytical platform designed for Sunpump.meme users. 
                      Our mission is simple: empower you to make informed decisions, spot hidden gems early, 
                      and avoid rug pulls. With Scan.meme, you get real-time insights into everything happening 
                      on the Sunpump.meme platform, all in one convenient view.
                    </p>                    
                  </div>
                  <div className="points">
                    <ul className="column">
                      <li><span className="point-circle"></span>Bonding Progress Monitoring</li>
                      <li><span className="point-circle"></span>Holders Realtime Update  </li>
                      <li><span className="point-circle"></span>Dynamic Charts</li>
                      <li><span className="point-circle"></span>Token Creation Analysis</li>
                    </ul>
                    <ul className="column">

                      <li><span className="point-circle"></span>Dev Wallet Monitoring</li>
                      <li><span className="point-circle"></span>Complete Project Overview</li>
                      <li><span className="point-circle"></span>Transaction Progress Tracking</li>
                      <li><span className="point-circle"></span>Volume Progress Updates</li>
                    </ul>
                    <ul className="column">
                      <li><span className="point-circle"></span>User Wallet Behavior Analysis</li>
                      <li><span className="point-circle"></span>Duplicate Token Detection</li>
                      <li><span className="point-circle"></span>Pumping Tokens Insight</li>
                      <li 
                        className="star"
                      >
                        <span className="point-circle"></span>
                        New wallets holdings
                        <div className="starbox">
                          <img 
                            src={star}  
                            data-tooltip-id="tooltip_login"
                            data-tooltip-content={'Available from Tier "Advanced" and above.'}                          
                          />
                        </div>
                      </li>
                    </ul>
                    <ul className="column">
                      <li 
                        className="star"         
                      >
                        <span className="point-circle"></span>
                        Scammer wallets holdings
                        <div className="starbox">
                          <img 
                            src={star}  
                            data-tooltip-id="tooltip_login"
                            data-tooltip-content={'Available from Tier "Advanced" and above.'}                 
                          />
                        </div>
                      </li>
                      <li 
                        className="star"     
                      >
                        <span className="point-circle"></span>
                        Traders holdings
                        <div className="starbox">
                          <img 
                            src={star}   
                            data-tooltip-id="tooltip_login"
                            data-tooltip-content={'Available from Tier "Advanced" and above.'}                    
                          />
                        </div>
                      </li>
                      <li 
                        className="star"
                      >
                        <span className="point-circle"></span>
                        Detection of Market Marker activity
                        <div 
                          className="starbox"
                          data-tooltip-id="tooltip_login"
                          data-tooltip-content={'Available from Tier "Master" and above.'} 
                        >
                          <img src={star}/>
                        </div>
                      </li>
                    </ul>
                  </div>
                  <div className="whyscanmeme">
                    <h2>Why Choose Scan.meme?</h2>
                    <div className="premium">
                      <div className="premium-item bg1">
                        <div className="premium-sign">Premium</div>
                        <div><img src={premium_icon1}/></div>
                        <div>
                          <b>Wallet AI Scanner</b>
                          <p>
                            key feature that analyzes wallet behaviors to assess the health and stability of a project by identifying scammer wallets, 
                            insiders/team wallets, and trader wallets. This feature helps predict risks and opportunities within a project.
                          </p>
                        </div>
                      </div>
                      <div className="premium-item bg2">
                        <div className="premium-sign">Premium</div>
                        <div><img src={premium_icon2}/></div>
                        <div>
                          <b>Bubblemaps</b>
                          <p>
                            A visual representation of blockchain transactions where each bubble represents a wallet. By connecting these bubbles, 
                            you can trace the flow of tokens, revealing the relationships between wallets in real-time!
                          </p>
                        </div>
                      </div>
                    </div>
                    <div className="flex">
                      <div className="grid-item">
                        <div className="icon">
                          <img src={icon4} width={42} alt=""/>
                        </div>
                        <div>
                          <b>Comprehensive Analysis</b>
                          <p>Get a complete picture of the Sunpump.meme ecosystem with real-time data at your fingertips.</p>                            
                        </div>
                      </div>
                      <div className="grid-item">
                        <div className="icon">
                          <img src={icon3} width={47} alt=""/>
                        </div>
                        <div>
                          <b>User-Friendly Interface</b>
                          <p>Navigate through data with ease using our intuitive platform designed for both novice and expert users.</p>                            
                        </div>
                      </div>
                      <div className="grid-item">
                        <div className="icon">
                          <img src={icon2} width={68} alt=""/>
                        </div>
                        <div>
                          <b>Early Detection</b>
                          <p>Spot potential rugs and gems before anyone else, thanks to our cutting-edge analytics.</p>                            
                        </div>
                      </div>
                      <div className="grid-item">
                        <div className="icon">
                          <img src={icon1} width={55} alt=""/>
                        </div>
                        <div>
                          <b>Stay Connected</b>
                          <p>Always be in the loop with the latest developments, community discussions, and market trends.</p>                            
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="join-us">
                    <h3>Join the Community</h3>
                    <p>Stay connected with other like-minded investors and developers. Follow us on our social media channels and become a part of the Scan.meme community.</p>
                  </div>

                </div>
              </div>
            </CardBody>
          </div>



          <div className="mt-5 text-center login-footer">
            <p>
              © {new Date().getFullYear()} Scan.meme v{versions[0].version}<br/>
              <span className="footer-title">
                <button
                  className="link-btn"
                  onClick={()=>{
                    tog_standard()
                    setModal_data(policy())
                    setModal_title('Privacy')
                  }}
                >Privacy Policy</button> | Terms of Service
              </span>
            </p>
          </div>
        </div>       
      </div>

      <Modal
        isOpen={modal_standard}
        toggle={() => {
          tog_standard();
        }}
        size="md"
      >
        <div className="modal-header">
          <h5 className="modal-title mt-0" id="myModalLabel">
            {modal_title}
          </h5>
          <button
            type="button"
            onClick={() => {
              setmodal_standard(false);
            }}
            className="close"
            data-dismiss="modal"
            aria-label="Close"
           >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div className="modal-body">
          {modal_data}
        </div>
        <div className="modal-footer">
          <button
            type="button"
            onClick={() => {
              tog_standard();
            }}
            className="btn btn-secondary "
            data-dismiss="modal"
            style={{background: '#212227', border:0}}
          >
            Close
          </button>
        </div>
      </Modal>

      {window.innerWidth > 600 && <Tooltip id="tooltip_login" place="bottom" />}
    </React.Fragment>
  );
};

export default withRouter(Login);

Login.propTypes = {
  history: PropTypes.object,
};
