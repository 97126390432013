const initialState = {
  swipeView: '',
  screenWidth: ''
};

const swipeViewReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'SET_MY_VARIABLE':
      return {
        ...state,
        swipeView: action.payload,
      };

    case 'SET_SCREEN_WIDTH':
      return {
        ...state,
        screenWidth: action.payload,
      }; 
    default:
      return state;
  }
};

export default swipeViewReducer;